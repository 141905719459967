import {
  useEffect, useState,
} from 'react';
import { parseCookies, setCookie } from 'nookies';
import { Close } from '@mui/icons-material';
import { GlobalSearch } from 'components/GlobalSearch';
import {
  BlogPost, Post,
} from 'lib/types';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { daysAgo, yyyymmdd } from 'lib/utils/datetimeUtils';
import { A } from 'components/A';
import { BlogModule } from 'components/Blog/BlogModule';
import { ExploreModule, ExploreModuleLink } from 'components/ExploreModule';
import { Carousel } from 'components/Carousel';
import { AddEmailModal } from 'features/SignUp/SignUpSteps/AddEmailModal';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { AwaitUserAuth } from 'components/AwaitUserAuth';
import { Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { MyGondola } from './LoggedInModules/MyGondola';
import { NextSteps } from './LoggedInModules/NextSteps/NextSteps';
import { CreatorHighlights } from './LoggedOutModules/CreatorHighlights';
import { JobsModule } from './LoggedOutModules/JobsModule';

interface Props {
  blogPosts?: BlogPost[];
  exploreLinks: ExploreModuleLink[];
}

export const Home = ({
  blogPosts, exploreLinks,
}: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [showEmailModule, setShowEmailModule] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [posts, setPosts] = useState<Post[]>();
  const [postsAreLoading, setPostsAreLoading] = useState(true);
  const lastWeek = daysAgo(7);
  const lastWeekString = yyyymmdd(lastWeek);

  useEffect(() => {
    /**
     * Returns true if we should show the maintenance banner. That is:
     * 1. The user has not dismissed the banner in the past week
     * 2. The current time is before the maintenance downtime (+10 minutes buffer)
     */
    const shouldShowBanner = () => {
      const cookies = parseCookies();

      const downtimeStart = DateTime.fromISO('2024-03-16T04:10:00', { zone: 'America/New_York' });
      const currentTime = DateTime.now().setZone('America/New_York');
      const bannerTime = DateTime.fromMillis(parseInt(cookies?.banner || '0', 10));
      const isBannerExpired = !cookies?.banner
        || bannerTime < DateTime.now().minus({ week: 1 });

      if (currentTime < downtimeStart && isBannerExpired) {
        setShowBanner(true);
        return;
      }

      setShowBanner(false);
    };

    const getTrendingPosts = async () => {
      const trendingPosts = await API.getTrendingPosts();
      setPosts(trendingPosts);
      setPostsAreLoading(false);
    };

    getTrendingPosts();
    setShowEmailModule(!!currentUser?.id && !currentUser?.credentials?.length);
    shouldShowBanner();
  }, [currentUser, API]);

  const showAddEmailModal = () => {
    const modal = showModal(AddEmailModal, {
      onCancel: () => {
        modal.destroy();
      },
      onSave: () => {
        enqueueSnackbar('You may now login to Gondola with your email address!', { variant: 'success' });
        setShowEmailModule(false);
        modal.destroy();
        // Re-load home so we get the currentUser with the new credential
        window.location.href = '/';
      },
    });
  };

  const dismissBanner = () => {
    setCookie(null, 'banner', new Date().getTime().toString());
    setShowBanner(false);
  };

  const emailModule = showEmailModule && (
    <div className="w-full p-6 bg-hintgray">
      <div className="max-w-3xl m-auto">
        <p className="mb-4">
          We noticed that you currently login to Gondola using Twitter.
          That&apos;s great! But we want to make sure you always have
          access to your Gondola account, regardless of what happens with Twitter
          in the future.
        </p>
        <p className="mb-4">
          This will allow you to sign in to Gondola with Twitter (as you do now),
          or sign in with an email and password. You can use the
          same email you are using as your contact email, or choose a different one.
        </p>
        <button type="button" className="btn-primary" onClick={() => showAddEmailModal()}>
          Add an email login
        </button>
      </div>
    </div>
  );

  const renderTrendingPosts = () => {
    if (postsAreLoading) {
      return (
        <div className="max-w-screen-xl mx-auto p-6">
          <div><Skeleton variant="text" width="100%" height={50} /></div>
          <div className="hidden sm:block"><Skeleton variant="rectangular" width="100%" height={400} /></div>
          <div className="sm:hidden"><Skeleton variant="rectangular" width="100%" height={400} /></div>
        </div>
      );
    }
    if (!posts?.length) {
      return null;
    }

    return (
      <div className="max-w-screen-xl m-auto p-6 mb-4 w-full">
        <div className="flex flex-wrap justify-between items-end">
          <h2 className="text-2xl font-bold">Recent Trending Posts</h2>
          <div className="text-lg">
            <A
              href={`/explore?sort=totalEngagements&minPostedAt=${lastWeekString}`}
              className="underline"
              suppressHydrationWarning
            >
              View all recent posts
            </A>
          </div>

        </div>
        <Carousel posts={posts} />
      </div>
    );
  };

  return (
    <div role="main">
      {showBanner && (
        <div className="bg-paleyellow font-semibold text-center align-middle sm:mt-1 py-4">
          Gondola will be down for maintenance, Saturday March 16, 4:00am EDT
          <button type="button" onClick={dismissBanner} aria-label="dismiss banner">
            <Close className="ml-4 bg-hintgray rounded-full" fontSize="small" />
          </button>
        </div>
      )}
      <div className="bg-alpineTeal text-center px-6 py-12">
        <h1 className="text-white font-bold text-xl pb-2">
          Search or track any social media content
        </h1>
        <div className="max-w-md m-auto">
          <GlobalSearch />
        </div>
      </div>
      <AwaitUserAuth>
        {emailModule}
        <NextSteps />
      </AwaitUserAuth>
      {renderTrendingPosts()}
      <MyGondola />
      <JobsModule />
      <CreatorHighlights />
      <div className="bg-orange">
        <ExploreModule links={exploreLinks} />
      </div>
      <BlogModule blogPosts={blogPosts} />
    </div>
  );
};
